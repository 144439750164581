.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/template.png'); /* Specify the path to your background image */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(5px); 
    animation: popup 0.5s ease forwards; /* Apply the pop-up animation */
    opacity: 0; /* Start with opacity 0 */
    transform: scale(0.5);
    z-index: 1000;
}

.overlay .modal {
    background-color: transparent;
    border-radius: 8px;
    width: 35%;
    max-width: 35%;
    height: 60%;
    max-height: 60%;
    overflow-y: auto;
}

.callToAction {
    border-radius: 10px;
    box-shadow: 0 0 10px red; /* Add red shadow */
    margin-right: 50px;
}

.button-with-arrow {
    border: 1px solid #FF0000;
    background: #FF0000;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: white;
    padding: 12px 20px; /* Adjust padding to increase button size */
    font-size: 16px; /* Adjust font size */
    height: 50px; /* Adjust height */
    width: 100%;
    justify-content: center;
    border-radius: 10px;
  }
  
  .button-with-arrow:focus {
    outline: none;
  }
  
  .button-with-arrow > svg {
    margin-right: 5px; /* Adjust spacing between icon and text */
  }

@keyframes popup {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}


.closeButton {
    background-color: transparent;
    color: gray;
    border: none;
    width: auto;
    font-size: 20px;
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: calc(30.00%); /* Adjust as needed */
    cursor: pointer;
    z-index: 999; /* Ensure the button is above other elements */
}

.scoreContainer {
    display: flex;
    flex-direction: row;
}

.totalScore {
    display: flex;
    font-size: 25px;
    font-weight: bold;
}

.detailsButton {
    background-color: transparent;
    color: gray;
    border: none;
    font-size: 18px;
    text-decoration: underline;
    width: auto;
    margin-left: 10px;
    padding-bottom: 15px;
}

.download-icon-container {
    top: 0;
    right: 0;
    z-index: 1000; /* Ensure it's above other elements */
}

/* Styles for the download icon */
.download-icon {
    color: #808080; /* Grey color */
    cursor: pointer; /* Ensure it looks clickable */
    font-size: 24px; /* Adjust size as needed */
}

.download-icon.loading {
    opacity: 0.5; /* Reduce opacity to visually appear as disabled */
    pointer-events: none; /* Disable pointer events to make it not clickable */
}

#tableSectionA table,
#tableSectionB table,
#tableSectionC table,
#tableSectionD table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    margin-bottom: 15px;
}

#tableSectionA th, #tableSectionA td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

#tableSectionA tr:nth-child(even) {
    background-color: #f2f2f2;
}

#tableSectionB th, #tableSectionB td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

#tableSectionB tr:nth-child(even) {
    background-color: #f2f2f2;
}

#tableSectionC th, #tableSectionC td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

#tableSectionC tr:nth-child(even) {
    background-color: #f2f2f2;
}

#tableSectionD th, #tableSectionD td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

#tableSectionD tr:nth-child(even) {
    background-color: #f2f2f2;
}

#tableSectionA h3,
#tableSectionB h3,
#tableSectionC h3,
#tableSectionD h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

#tableSectionA table th,
#tableSectionB table th,
#tableSectionC table th,
#tableSectionD table th,
#tableSectionA table td,
#tableSectionB table td,
#tableSectionC table td,
#tableSectionD table td {
    padding: 8px;
    border: 1px solid #dddddd;
    text-align: left;
}

#tableSectionA table th:first-child,
#tableSectionB table th:first-child,
#tableSectionC table th:first-child,
#tableSectionD table th:first-child,
#tableSectionA table td:first-child,
#tableSectionB table td:first-child,
#tableSectionC table td:first-child,
#tableSectionD table td:first-child {
    width: 80%;
}

#tableSectionA table th:last-child,
#tableSectionB table th:last-child,
#tableSectionC table th:last-child,
#tableSectionD table th:last-child,
#tableSectionA table td:last-child,
#tableSectionB table td:last-child,
#tableSectionC table td:last-child,
#tableSectionD table td:last-child {
    width: 20%;
}


.sectionContainer {
    display: flex;
    justify-content: space-between; /* Adjust alignment as needed */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add black shadow */
    border-radius: 5px;
    margin-bottom: 10px;
}

#tableSectionA,
#tableSectionB {
    flex: 1; /* Both sections will take equal width */
    margin-right: 20px; /* Adjust spacing between sections */
}

#tableSectionB {
    margin-right: 0; /* Remove margin on the right of the last section */
}

#tableSectionC,
#tableSectionD {
    flex: 1; /* Both sections will take equal width */
    margin-right: 20px; /* Adjust spacing between sections */
}

#tableSectionD {
    margin-right: 0; /* Remove margin on the right of the last section */
}

.total-row {
    background-color: #ffacac; /* Set your desired background color */
}

.subtotal {
    background-color: #fcd4d4;
}

    /* CSS for small screens */
    @media only screen and (max-width: 968px) {
        .sectionContainer {
            flex-direction: column; /* Change flex direction to stack items vertically */
        }
    
        #tableSectionA,
        #tableSectionB,
        #tableSectionC,
        #tableSectionD {
            width: 100%; /* Full width for each section on small screens */
            max-width: 100%;
            font-size: 12px;
            margin-right: 0; /* Remove margin between sections */
            margin-bottom: 20px; /* Add some space between sections */
        }
    
        .overlay .modal {
            width: 70%; /* Make modal wider on small screens */
            max-width: 70%;
            max-height: 40%; /* Adjust max-height for better fit on small screens */
        }

        .callToAction {
            border-radius: 5px;
            box-shadow: 0 0 10px red; /* Add red shadow */
            margin-right: 20px;
        }
    
        /* Adjust font size for smaller screens */
        table {
            font-size: 10px; /* Decrease font size of all tables */
        }
    
        /* Adjust width of table cells for smaller screens */
        th, td {
            padding: 4px; /* Decrease padding for table cells */
        }
    
        .closeButton {
            background-color: transparent;
            color: gray;
            border: none;
            width: auto;
            font-size: 20px;
            position: fixed;
            top: 2vh; /* Adjust as needed */
            right: 18vw;
            display: inline-block;
            cursor: pointer;
            z-index: 999; /* Ensure the button is above other elements */
        }
    
        .totalScore {
            display: flex;
            font-size: 15px;
            font-weight: bold;
        }
        
        .detailsButton {
            background-color: transparent;
            color: gray;
            border: none;
            font-size: 12px;
            text-decoration: underline;
            width: auto;
            margin-left: 10px;
            padding-bottom: 20px;
        }
    }
    
    @media only screen and (orientation: landscape) {
        table {
            font-size: 12px; /* Adjust font size for landscape mode */
            width: 80%; /* Adjust table width for landscape mode */
            margin: 0 auto; /* Center the table horizontally */
        }
    
        th, td {
            padding: 6px; /* Adjust padding for table cells in landscape mode */
            width: auto; /* Allow cells to adjust width based on content */
        }
    
        .totalScore {
            font-size: 18px; /* Increase font size for total score in landscape mode */
        }
    
        .detailsButton {
            font-size: 14px; /* Adjust font size for details button in landscape mode */
        }
    }
    
    

        @media only screen and (max-width: 720px) {
            .closeButton {
                background-color: transparent;
                color: gray;
                border: none;
                width: auto;
                font-size: 20px;
                position: fixed;
                top: calc(5vw);
                right: 8vw;
                display: inline-block;
                cursor: pointer;
                z-index: 999; /* Ensure the button is above other elements */
            }
        }



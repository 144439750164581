.box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px; /* Set rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    background-color: #fff; /* Set background color */
    padding: 20px; /* Add padding for better appearance */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px; /* Optional: Set maximum width for responsiveness */
}

.logo img {
    width: 250px;
}

.input-wrapper {
    position: relative;
    padding: 35px 0 0;
    flex-direction: column;
    
}

.input-field {
    border: 0;
    border-bottom: 1px solid #A20408;
    outline: 0;
    font-size: 16px;
    color: black;
    padding: 4px 0px;
    padding-right: 32px;

    background: transparent;
    transition: 0.2s;
}

label {
    position: absolute;
    padding-top: 35px;
    top: 0;
    left: 0;
    font-size: 1em;
    display: flex;
    color: #A5373b;
    align-items: center;
    transition: .3s ease-in-out;
    pointer-events: none; /* Make it not clickable */
}

input:focus + label,
input:valid + label {
    color: #A5373b;
    font-size: 0.75em;
    top: -20px;
    pointer-events: none;
}

.login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #A20408;
    border: none;
    border-radius: 5px;
    padding: 15px; /* Increase button padding for a larger size */
    cursor: pointer;
    margin-top: 15px;
    transition: 0.3s ease;
    width: 250px;
    font-size: 16px;
}

.login-btn:hover {
    background-color: #900106;
}

.eye-icon {
    position: absolute;
    top: 75%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #A5373b;
}

input[type="text"] + label {
    pointer-events: all;
}
p, ul, li, button {
    font-family: 'Montserrat', sans-serif;
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    backdrop-filter: blur(5px); /* Adjust blur radius as needed */
    z-index: 4; /* Ensure it's below the popup */
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 4;
    opacity: 0;
    animation: popupAppear 0.3s ease forwards; 
    overflow-y: auto;
}


.popup h2 {
    margin-top: 0;
}

.popup label {
    display: block;
}

.popup button {
    margin-top: 15px; /* Reduce top margin */
    padding: 4px 12px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
  }

.popup ul {
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
}

.agree {
    display: flex;
    flex-direction: column;
}

.agree .agreeButton {
    display: flex;
    justify-content: flex-end;
}
  
.checkbox-text {
    display: flex;
    margin-top: 10px;
}   

.checkbox-text input[type="checkbox"] {
    margin-right: 10px; 
    margin-top: 2px;

}

@keyframes popupAppear {
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5); /* Start position and scale */
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1); /* End position and scale */
    }
}

@media only screen and (max-width: 720px) {
    .popup {
      width: 90%;
      max-height: 70%;
      overflow-y: auto;
      z-index: 6;
    }
  }
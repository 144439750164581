/* LanguageSelector.css */
.language-selector {
    position: absolute;
    top: 20px;
    right: 0px;
    display: inline-block;
    text-align: right; /* Align the content to the right */
    z-index: 9999; 
    background-color: white;
    border-radius: 10px;
    width: auto;
  }
  
  .selected-language {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .language-selector img {
    width: 20px; /* Adjust the width based on your flag image size */
    margin-right: 5px;
    border-radius: 50%;
  }
  
  .language-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 8px;
  }
  
  .language-option {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 3px 0px 3px;
  }
  
  .language-option:hover {
    background-color: #f0f0f0;
  }
  

  @media screen and (max-width: 960px) {
    .language-selector {
      position: absolute;
      top: 20px;
      display: flex;
      display: inline-block;
      text-align: right; /* Align the content to the right */
      background-color: white;
      width: auto;
      z-index: 2; 
    }
  }
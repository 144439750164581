/* MyDropdown.css */

p, ul, li, button {
    font-family: 'Montserrat', sans-serif;
}

select {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;   
    font-style: normal;
}

p {
    text-align: justify;
    margin-top: 10px;
    font-size: 14px;
}

ol > li::marker {
    font-weight: bold;
  }

li {
    margin-top: 1px;
    font-size: 13px;
    text-align: justify;
}

.language-test-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

select {
    margin-top: 5px;
    margin-left: 4px;
}

.languageSubQuestion {
    margin-top: 20px;
    font-size: 14px;
}

.main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.express-entry-category {
    display: flex;
    right: 0;
    margin-top: 60px;
    position: absolute;
    width: 27.5%;
    height: auto;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add black shadow */
    z-index: 2; /* Ensure the z-index is higher than that of the footer */
}

.normal-div {
    display: flex;
    position: absolute;
    width: 100%;
    height: auto;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add black shadow */
    z-index: 3; /* Ensure the fixed div stays on top */
}
  
.fixed-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: add shadow for better visibility */
    z-index: 3; 
}

.express-entry-category .express-entry-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.express-entry-category .express-entry-title p {
    font-weight: 600;
}

.express-entry-category .express-entry-table {
    display: flex;
    flex-direction: column;
}

.express-entry-category .express-entry-table .category {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.category .category-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid black;
    margin-bottom: 10px;
    cursor: pointer;
}

.arrow {
    transition: transform 0.4s;
  }
  
  .category-header.expanded .arrow {
    transform: rotate(90deg); /* Apply rotation only when expanded */
}

.category-header.expanded .arrow-left {
    transform: rotate(270deg); /* Apply rotation only when expanded */
}

.category .category-header p {
    margin-left: 10px;
}

.category .category-header span {
    cursor: pointer;
    margin-top: 13px;
}

.category .category-details {
    margin-bottom: 20px;
    z-index: 1000;
}

.category .category-details table {
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 10px;
}

.express-entry-category .points {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.dropdown-container {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    width: 90%; /* Limit the width to 50% */
    margin-top: 60px; /* Add margin top */
    margin-bottom: 60px; /* Add margin bottom */
    margin-left: calc(1%); /* Align to the left side */
    min-height: 800px; /* Set a minimum height */
    height: auto;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add black shadow */
}


.dropdown-container * {
    padding: 5px;
}

.dropdown-container .container {
    width: auto; /* Limit the maximum width of the list to its container */
    overflow-x: auto; /* Enable horizontal scrolling when content overflows */
}

.dropdown-container .container > * {
    white-space: normal; /* Allow text to wrap within child elements */
  }

.dropdown-container .container ul, ol, li{
    margin-left: 15px;
}


.selection,
.selection-education {
    width: 220px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #333;
    border-radius: 5px;
    color: #333;
    appearance: none;
    background-image: url('/src/components/images/arrow.svg');
    background-repeat: no-repeat;
    background-position: center right 2px;
}

.selection::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid red;
    transform: translateY(-50%);
}


button {
    font-size: 18px; /* Adjust the font size to make it bigger */
    color: white;    /* Set text color to white */
    background-color: #FF0000; /* Set background color to #FF0000 (red) */
    padding: 10px 20px; /* Adjust padding for a better visual appearance */
    border: none; /* Remove default button border */
    cursor: pointer; /* Change cursor to pointer for better interaction */
    border-radius: 7px;
    border: 1.5px solid #FF0000;
    width: 250px;
    height: 50px;
    margin-top: 10px;
  }

button:hover {
    color: #FF0000;
    background-color: white;
  }

.toggle {
    display: none;
}



.fixed-divMobile {
    display: flex;
    z-index: 2;
}

.normal-divMobile {
    display: flex;
    z-index: 2;
}



.toggle {
    display: flex;
    position: fixed;
    top: calc(50.75%);
    right: 0;
    bottom: 0; /* Adjusted to start from the bottom */
    transform: translateY(-50%);
    background-color: #fff;
    width: 2%; /* Adjust width as needed */
    height: 50vh; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.3s, right 0.3s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add black shadow */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 3;
  }
  
  .expandToggle {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    padding: 10px;
    height: 100%;
    transition: height 0.3s, right 0.3s;
    flex-direction: column;
    z-index: 999;
  }

  .toggle-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Adjust opacity as needed */
    backdrop-filter: blur(5px); /* Adjust blur radius as needed */
    z-index: 8; /* Ensure it's below the content but above the background */
  }
  
  
.content {
    display: flex;
    align-items: left; /* Align content vertically */
    justify-content: center; /* Align content horizontally */
    height: auto;
    width: 100%;
    flex-direction: row;
}

.toggle-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;
    writing-mode: vertical-lr;
    width: 50px;
}

.expandToggle .toggle-header {
    height: 100%;
    border-right: 1px solid #555;
    margin-right: 5px;
}

.toggle-header .arrow {
    margin-top: 10px;
}

.toggle .expand-content {
    display: none;
}

.expandToggle .expand-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.content .arrow-left {
    transition: transform 0.4s;
}
    
.expandToggle .arrow-left {
    transform: rotate(180deg); /* Apply rotation only when expanded */
}

  @media screen and (max-width: 960px) {    
    .dropdown-container {
        position: static;
        display: flex;
        align-items: left;
        top: auto;
        flex-direction: row; /* Change to row */
        flex-wrap: wrap; /* Allow items to wrap */
        height: auto; /* Adjust height as needed */
        padding: 5px;
        width: 90%;
      }


    button {
        display: block; /* Make the button visible */
        width: 250px;
        height: 50px;
        margin-top: 10px;
    }

    .toggle {
        display: flex;
        position: fixed;
        top: calc(50.75%);
        right: 0;
        bottom: 0; /* Adjusted to start from the bottom */
        transform: translateY(-50%);
        background-color: #fff;
        width: 7%; /* Adjust width as needed */
        height: 50vh; /* Adjust height as needed */
        display: flex;
        align-items: center;
        justify-content: center;
        transition: width 0.3s, right 0.3s;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Add black shadow */
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        z-index: 3;
      }

      .expandToggle {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 10px;
        height: 100%;
        transition: height 0.3s, right 0.3s;
        flex-direction: column;
        z-index: 999;
      }
    
      .toggle-background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8); /* Adjust opacity as needed */
        backdrop-filter: blur(5px); /* Adjust blur radius as needed */
        z-index: 8; /* Ensure it's below the content but above the background */
      }
      
      
    .content {
        display: flex;
        align-items: left; /* Align content vertically */
        justify-content: center; /* Align content horizontally */
        height: auto;
        width: 100%;
        flex-direction: row;
    }
    
    .toggle-header {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-direction: row;
        writing-mode: vertical-lr;
        width: 50px;
    }
    
    .toggle-header .arrow {
        margin-top: 10px;
    }
    
    .toggle .expand-content {
        display: none;
    }
    
    .expandToggle .expand-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
    }
    
    .content .arrow {
        transition: transform 0.4s;
    }
        
    .expandToggle .arrow {
        transform: rotate(180deg); /* Apply rotation only when expanded */
    }


    .category .category-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid black;
        margin-bottom: 10px;
        cursor: pointer;
    }
}


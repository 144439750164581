@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
  }
  
  .home,
  .crsTool,
  .preAssessment {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .home {
    position: relative;
    /* background-image: url('./images/bg.jpg'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    color: #000;
    font-size: 100px;
  }
  
  .crsTool {
    position: relative;
    /* background-image: url('./images/bg.jpg'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    color: #000;
    font-size: 100px;
  }
  
  .preAssessment {
    position: relative;
    /* background-image: url('./images/bg.jpg'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    color: #000;
    font-size: 100px;
  }
  
* {
  font-family: 'Montserrat', sans-serif;
}

.navbar {
    background: #fff;
    height: 60px;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 1.2rem;
    border-bottom: 3px solid #FF0000;
  }
  
  .navbar-logo {
    max-width: 300px;
    height: auto;
  }
  
  .nav {
    color: #FF0000;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
  }


  
  .nav-links {
    color: #FF0000;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: #FF0000;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #FF0000;
    margin-top: 55px;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .social-media {
    margin-right: auto;
}

.social-media ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px; /* Adjust the margin as needed */
}

.social-media ul li {
  margin-left: 10px; /* Adjust the margin between social media elements */
}

.social-media img {
  width: 6vw; /* Set a width using a percentage of the viewport width (vw) */
  max-width: 35px; /* Set a maximum width if needed */
  height: auto; /* Maintain aspect ratio */
}

select {
  padding: 8px;
  font-size: 16px;
}

  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .navbar {
      height: 30px;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 85vh;
      position: absolute;
      top: 150px;
      left: -100%;
      opacity: 1;
      justify-content: flex-start;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #fff;
      justify-content: start;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #FF0000;
      border-radius: 0;
    }
  
    .navbar-logo {
      max-width: 200px;
      justify-self: center;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
    
    .social-media {
      margin-bottom: 30px;
    }
  
    .fa-times {
      color: #FF0000;
      font-size: 2rem;
      margin-top: 55px;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #FF0000;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #FF0000;
      color: #fff;
      transition: 250ms;
    }
  
    button {
      display: none;
    }
  }
  



span {
    font-family: 'Montserrat', sans-serif;
}

.rights {
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
    font-style: normal;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #2F2E2E;
    text-align: center;
    height: auto;
    position: relative; /* Add position property */
    z-index: 0; /* Set z-index value */
}


.footer-logo img { 
    width: 250px;
    height: auto;
}

.separator {
    position: relative;
    top: 20px;
    border: none;
    height: 1.2px;
    width: 150px;
    background: white;
    margin-bottom: 50px;
}

.location-icon img {
    color: white;
    font-size: 1.25rem;
    margin-right: 5px;
    width: 30px;
}

.fa-location-dot {
    color: white;
    font-size: 1.25rem;
    margin-right: 5px;
}

.address {
    justify-content: center;
    align-items: center;
    color: white;
}

.copyright {
    color: white;
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}